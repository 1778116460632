import {endWith, ignoreElements, merge, Observable, of, Subscriber} from 'rxjs';
import {NgZone} from '@angular/core';
import {debounceTime, filter, take, takeUntil} from 'rxjs/operators';
import {ToastService} from '../../../@core/toast.service';

export interface SpeechToTextOptions {
    continuous?: boolean;
    timeoutSecs?: number;
    replacePunctuation?: boolean;
}

const PUNCTUATION_MAP: {[key: string]: string} = {
    ' comma': ',',
    ' semicolon': ';',
    ' colon': ':',
    ' period': '.',
    ' exclamation mark': '!',
    ' question mark': '?',
};

export abstract class SpeechToText<T = any> extends Observable<string> {
    protected abstract _recognition$: Observable<string>;
    protected _options: SpeechToTextOptions;
    protected _isListening: boolean;
    protected _subscribers: Subscriber<string>[] = [];

    abstract get defaultOptions(): SpeechToTextOptions;

    get isListening() {
        return this._isListening;
    }

    get isProcessing() { // eslint-disable-line
        return false;
    }

    constructor(protected zone: NgZone, options?: SpeechToTextOptions) {
        super(subscriber => {
            const recSub = this._recognition$.subscribe(subscriber);
            this._subscribers.push(subscriber);

            return () => {
                recSub.unsubscribe();
                this._subscribers = this._subscribers.filter(x => x !== subscriber);
                if (this._subscribers.length === 0) this.stop();
            };
        });
        this._setOptions(options);
    }

    abstract stop();

    protected abstract _parseString(res: T): string;

    private _setOptions(options?: SpeechToTextOptions) {
        this._options = {...this.defaultOptions, ...(options || {})};
    }

    protected _processResult(res: T) {
        let text = this._parseString(res);
        text = this._replacePunctuation(text);
        text = text.replace(/([.!?])\s+([a-z])/g, (_, p1, p2) => `${p1} ${p2.toUpperCase()}`);
        return `${text[0].toUpperCase()}${text.slice(1)}`;
    }

    protected _replacePunctuation(result: string) {
        if (this._options.replacePunctuation) {
            Object.entries(PUNCTUATION_MAP).forEach(([word, symbol]) => {
                result = result.replace(new RegExp(word, 'gi'), symbol);
            });
        }
        return result;
    }

    protected _initiateRecordingTimeout(timeoutSecs = this._options.timeoutSecs) {
        if (typeof timeoutSecs !== 'number') return;
        merge(of(null), this._recognition$.pipe(filter(x => !!x))).pipe(
            debounceTime(timeoutSecs * 1000),
            take(1),
            takeUntil(this._recognition$.pipe(ignoreElements(), endWith())),
        ).subscribe(() => this.stop());
    }

    protected _propagateError(message: string, subscriber: Subscriber<any>) {
        subscriber.error(message);
        ToastService.error(message);
    }
}
